import { useEffect, useState } from "react"
import { useSizeStore } from 'src/zustand/useSizeStore'

type windowSize = {
    width: number,
    height: number
}

export const useWindowSize = () => {
  const { setSize } = useSizeStore()

  useEffect(() => {
    if (typeof window !== "undefined") {

      const handleResize = () => {
        if (window.innerWidth <= 384) {
          setSize('square')
        } else {
          if (window.innerWidth > window.innerHeight) {
            if (window.innerHeight / 2 * 3 < window.innerWidth) {
              setSize('default')
            } else {
              setSize('tablethorizontal')
            }
          } else {
            if (window.innerWidth / 2 * 3 < window.innerHeight) {
              setSize('vertical')
            } else {
              setSize('tabletvertical')
            }
          }
        }
      }

      window.addEventListener("resize", handleResize)
      handleResize()
      return () => window.removeEventListener("resize", handleResize)
    } else {
      return
    }
  }, [])
  
  return
}
