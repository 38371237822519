import { create } from 'zustand'

type screenSize = {
  size: string,
  setSize: (size: string) => void
}

export const useSizeStore = create<screenSize>((set) => ({
  size: 'default',
  setSize: ( param ) => set(( state ) => ({ size: param })),
}))
