import { Icc } from 'generated/backendSchemas'
import { Util } from 'src/utils'
import { getWindDirectionText, getWindImageLED } from '../util'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import { useSizeStore } from 'src/zustand/useSizeStore'

SwiperCore.use([Autoplay])

export const ExamplePageDefault = ({
  average_wind_speed,
  barometric_pressure,
  highest,
  humidity,
  lowest,
  max_wind_speed,
  rainfall,
  rainfall_24,
  rainfall_day,
  sunrise_time,
  sunset_time,
  temperature,
  uvi,
  wbgt_value,
  wind_direction,
  display_date,
  contract_name
}: Icc) => {
  const { size } = useSizeStore()

  return (
    <div style={{ height: '100vh' }}>
      <Swiper
        autoplay
        loop={true} // REF: https://zenn.dev/attt/articles/swiper-loop-without-duplicate
        style={{ height: '85vh' }}
      >
        <SwiperSlide
          key={ 1 }
          data-swiper-autoplay={ 5000 }
        >
          <div className="Weather_gauge horizontal" style={{ backgroundColor : '#222', padding : '0px'}}>
            <ul className="WeatherGaugeList" style={{ height : '85vh', width : '100vw' }}>
              <li className="WeatherGaugeList__Item" style={{ height : '85vh', width : '100vw', backgroundColor : '#444', color : '#EEE' }}>
                <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Temp">
                  気温
                </h2>
                <div className="WeatherGaugeList__Value" style={{ fontSize : '24vw', textAlign : 'right', paddingRight : '10px' }}>
                  {temperature}
                  <span className="WeatherGaugeList__Value--Unit" style={{ fontSize : '9vw' }}>℃</span>
                </div>

                <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Humidity" style={{ paddingTop : '1vw' }}>
                  湿度
                </h2>
                <div className="WeatherGaugeList__Value" style={{ fontSize : '24vw', textAlign : 'right', paddingRight : '10px' }}>
                  {humidity}
                  <span className="WeatherGaugeList__Value--Unit" style={{ fontSize : '9vw' }}>%</span>
                </div>
              </li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide
          key={ 2 }
          data-swiper-autoplay={ 5000 }
        >
          <div className="Weather_gauge horizontal" style={{ backgroundColor : '#222', padding : '0px'}}>
            <ul className="WeatherGaugeList" style={{ height : '85vh', width : '100vw' }}>
              <li className="WeatherGaugeList__Item" style={{ height : '85vh', width : '100vw', backgroundColor : '#444', color : '#EEE' }}>
                <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Rainfall">
                  雨量
                </h2>
                <p className="WeatherGaugeList__Value--Unit" style={{ fontSize : '9vw', paddingRight : '5%', textAlign : 'right', fontWeight: 'bold' }}>
                  1時間雨量
                  <span className="WeatherGaugeList__Value--Numbers" style={{ fontSize : '30vw', lineHeight : '1.0' }}>{rainfall}</span>
                  <span className="WeatherGaugeList__Value--Unit" style={{ fontSize : '9vw' }}>mm</span>
                </p>
                <div className="" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight : '5%' }}>
                  <div className="WeatherGaugeList__Other--Day" style={{ fontSize: '6vw' }}>
                    24時間雨量
                    <span className="WeatherGaugeList__Other--Numbers" style={{ fontSize : '12vw', lineHeight : '12vw' }}>
                      {rainfall_24}
                    </span>
                    mm
                  </div>
                </div>

                <div className="" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight : '5%' }}>
                  <div className="WeatherGaugeList__Other--Day" style={{ fontSize: '6vw', width: '100%', textAlign: 'right' }}>
                    1日雨量
                    <span className="WeatherGaugeList__Other--Numbers" style={{ fontSize : '12vw', lineHeight : '12vw' }}>
                      {rainfall_day}
                    </span>
                    mm
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide
          key={ 3 }
          data-swiper-autoplay={ 5000 }
        >
          <div className="Weather_gauge horizontal" style={{ backgroundColor : '#222', padding : '0px'}}>
            <ul className="WeatherGaugeList" style={{ height : '85vh', width : '100vw' }}>
              <li className="WeatherGaugeList__Item" style={{ height : '85vh', width : '100vw', backgroundColor : '#444', color : '#EEE' }}>
                <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Speed">
                  風速
                </h2>
                <p className="WeatherGaugeList__Value _WeatherGaugeList__Value--Average" style={{ fontSize : '9vw', padding : '5%', textAlign : 'right' }}>
                  平均風速
                  <span className="WeatherGaugeList__Value--Numbers" style={{ fontSize : '18vw', lineHeight : '20vh' }}>
                    {average_wind_speed}
                  </span>
                  m/s
                </p>
                <p className="WeatherGaugeList__Value _WeatherGaugeList__Value--Maximum" style={{ fontSize : '9vw', padding : '5%', textAlign : 'right' }}>
                  最大風速
                  <span className="WeatherGaugeList__Value--Numbers" style={{ fontSize : '18vw', lineHeight : '20vh' }}>
                    {max_wind_speed}
                  </span>
                  m/s
                </p>
              </li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide
          key={ 4 }
          data-swiper-autoplay={ 5000 }
        >
          <div className="Weather_gauge horizontal" style={{ backgroundColor : '#222', padding : '0px'}}>
            <ul className="WeatherGaugeList" style={{ height : '85vh', width : '100vw' }}>
              <li className="WeatherGaugeList__Item" style={{ height : '85vh', width : '100vw', backgroundColor : '#444', color : '#EEE' }}>
                <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Direction">
                  風向き
                </h2>
                <div className="WeatherGaugeList__DirectionWrapper" style={{ paddingTop : '6vw' }}>
                  <img
                    src={getWindImageLED(wind_direction ?? 0)}
                    alt=""
                    className="WeatherGaugeList__Value WeatherGaugeList__DirectionIcon"
                    style={{ height : '45vw', width : '45vw' }}
                  />

                  <div className="WeatherGaugeList__DirectionInner">
                    <p className="WeatherGaugeList__Value WeatherGaugeList__Value--DirectionDegrees" style={{ fontSize : '6vw' }}>
                      {wind_direction ?? 0}°
                    </p>
                    <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Direction" style={{ fontSize : '6vw' }}>
                      {getWindDirectionText(wind_direction ?? 0)}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide
          key={ 5 }
          data-swiper-autoplay={ 5000 }
        >
          <div className="Weather_gauge horizontal" style={{ backgroundColor : '#222', padding : '0px'}}>
            <ul className="WeatherGaugeList" style={{ height : '85vh', width : '100vw' }}>
              <li className="WeatherGaugeList__Item" style={{ height : '85vh', width : '100vw', backgroundColor : '#444', color : '#EEE' }}>
                <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Pressure">
                  気圧
                </h2>
                <div className="WeatherGaugeList__Value WeatherGaugeList__Value--Pressure" style={{ fontSize : '15vw', display : 'flex', justifyContent : 'flex-end', paddingTop : '15vh' }}>
                  <div>{barometric_pressure}</div>
                  <div className="WeatherGaugeList__Value--Unit" style={{ fontSize : '6vw', lineHeight : '21vw' }}> hPa</div>
                </div>
              </li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide
          key={ 6 }
          data-swiper-autoplay={ 5000 }
        >
          <div className="Weather_gauge horizontal" style={{ backgroundColor : '#222', padding : '0px'}}>
            <ul className="WeatherGaugeList" style={{ height : '85vh', width : '100vw' }}>
              <li className="WeatherGaugeList__Item" style={{ height : '85vh', width : '100vw', backgroundColor : '#444', color : '#EEE' }}>
                <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--White WeatherGaugeList__Title--Wbgt">
                  換算WBGT
                </h2>
                <div className="WeatherGaugeList__Value" style={{ fontSize : '24vw', textAlign : 'center', lineHeight : '32vw', paddingTop : '15vh' }}>
                  { (temperature) && Math.floor( temperature ) }
                  <span className="WeatherGaugeList__Value--Unit" style={{ fontSize : '9vw' }}>℃</span>
                </div>
              </li>
            </ul>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="WeatherGaugeList__measurementPoint" style={{ backgroundColor : '#222', color : 'white', fontSize : '6vw', fontWeight : 'bold', textAlign : 'center', height : '15vh', lineHeight : '15vh' }}>
        { contract_name }
      </div>
    </div>

  )
}
