import loading from 'src/assets/images/loading.svg'

export const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '48px',
        height: '100vh',
        animation: 'rotation 4s linear infinite'
      }}
    >
      <img src={loading} alt="" />
    </div>
  )
}
