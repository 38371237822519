import SafetyPng from 'src/assets/images/heatstroke/new/img_WBGT_blue.svg'
import AttentionPng from 'src/assets/images/heatstroke/new/img_WBGT_green.svg'
import VigilancePng from 'src/assets/images/heatstroke/new/img_WBGT_yellow.svg'
import SevereVigilancePng from 'src/assets/images/heatstroke/new/img_WBGT_orange.svg'
import DangerPng from 'src/assets/images/heatstroke/new/img_WBGT_red.svg'

import TextAttentionPng from 'src/assets/images/heatstroke/text_attention.png'
import TextAttention2Png from 'src/assets/images/heatstroke/text_attention@2x.png'
import TextDangerPng from 'src/assets/images/heatstroke/text_danger.png'
import TextDanger2Png from 'src/assets/images/heatstroke/text_danger@2x.png'
import TextSevereVigilancePng from 'src/assets/images/heatstroke/text_s-vigilance.png'
import TextSevereVigilance2Png from 'src/assets/images/heatstroke/text_s-vigilance@2x.png'
import TextVigilancePng from 'src/assets/images/heatstroke/text_vigilance.png'
import TextVigilance2Png from 'src/assets/images/heatstroke/text_vigilance@2x.png'
import TextSafetyPng from 'src/assets/images/heatstroke/text_safety.png'
import TextSafety2Png from 'src/assets/images/heatstroke/text_safety@2x.png'

import { Util } from 'src/utils'

export const getCharacterImageByWbgt = (wbgt: number) => {
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return DangerPng
    case 4:
      return SevereVigilancePng
    case 3:
      return VigilancePng
    case 2:
      return AttentionPng
    case 1:
      return SafetyPng
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}

export const getTextImagesByWbgt = (wbgt: number) => {
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return [TextDangerPng, TextDanger2Png]
    case 4:
      return [TextSevereVigilancePng, TextSevereVigilance2Png]
    case 3:
      return [TextVigilancePng, TextVigilance2Png]
    case 2:
      return [TextAttentionPng, TextAttention2Png]
    case 1:
      return [TextSafetyPng, TextSafety2Png]
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}

export const getBackgroundColorByWbgt = (wbgt: number) => {
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return 'is-red'
    case 4:
      return 'is-orange'
    case 3:
      return 'is-yellow'
    case 2:
      return 'is-green'
    case 1:
      return 'is-blue'
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}
