import {
  GetSlideshowResponse,
  useGetSlideshow
} from 'generated/backendComponents'
import SwiperCore from 'swiper'
import { useEffect, useRef, useState } from 'react'
import { useRenderEffect } from 'src/hooks'
import { useAuthContext } from 'src/contexts/AuthContext'
import { Movie } from 'generated/backendSchemas'
import { db } from 'src/db'

export const useSlideshow = () => {
  const mountedDateRef = useRef(new Date())
  const [getSlideshowResponse, setGetSlideshowResponse] =
    useState<GetSlideshowResponse>()

  const { refetch } = useGetSlideshow({}, { enabled: false })

  useRenderEffect(() => {
    const func = async () => {
      const { data } = await refetch()
      setGetSlideshowResponse(data)
    }
    func()
  })

  // NOTE: 何かしら壊れた時のために定期的にリロードする
  const reloadWhenOlder = () => {
    if (
      new Date().getTime() - mountedDateRef.current.getTime() >
      1000 * 60 * 30
    ) {
      // 2024.09.05 DO NOTHING...
      // location.reload()
    }
  }

  const handleAutoplay = (swiper: SwiperCore) => {
    // NOTE: スライドが1枚の時はがonChangeSlideが発動しないのでonAutoplayで処理する
    if (swiper.slides.length === 1) {
      // 2024.09.05 DO NOTHING... reloadWhenOlder()
      const func = async () => {
        const { data } = await refetch()
        setGetSlideshowResponse(data)
      }
      func()
    }
  }

  const [getSlideshowNewerResponse, setGetSlideshowNewerResponse] =
    useState<GetSlideshowResponse>()

  const handleSlideChange = (swiper: SwiperCore) => {
    if (swiper.isEnd) {
      const refetchAndCache = async () => {
        const { data } = await refetch()
        setGetSlideshowNewerResponse(data)
      }
      refetchAndCache()
    }

    if (swiper.isBeginning) {
      // 2024.09.05 DO NOTHING... reloadWhenOlder()
      if (getSlideshowNewerResponse) {
        setGetSlideshowResponse(getSlideshowNewerResponse)
        setGetSlideshowNewerResponse(undefined)
      }
    }
  }

  const swiperTouchStartXRef = useRef(0)
  const autoplayTimerIdRef = useRef<NodeJS.Timeout>()

  const handleTouchStart = (
    swiper: SwiperCore,
    e: MouseEvent | TouchEvent | PointerEvent
  ) => {
    const rerunAutoPlay = (swiper: SwiperCore, ms: number) => {
      swiper.autoplay.stop()
      clearTimeout(autoplayTimerIdRef.current)
      const timerId = setTimeout(() => {
        swiper.autoplay.start()
      }, ms)
      autoplayTimerIdRef.current = timerId
    }
    rerunAutoPlay(swiper, 5 * 60 * 1000)
    if ('touches' in e) {
      swiperTouchStartXRef.current = e.touches[0].clientX
    } else {
      swiperTouchStartXRef.current = e.clientX
    }
  }

  const handleTouchEnd = (
    swiper: SwiperCore,
    e: MouseEvent | TouchEvent | PointerEvent
  ) => {
    const tolerance = swiper.params.threshold ?? 0
    const totalSlidesLen = swiper.slides.length

    // 左にスワイプしたか右にスワイプしたかを判定
    const diff = (() => {
      if ('changedTouches' in e) {
        return e.changedTouches[0].clientX - swiperTouchStartXRef.current
      } else {
        return e.clientX - swiperTouchStartXRef.current
      }
    })()

    // 最初のスライド上で左にスワイプされたときは最後のスライドに飛ばす
    if (swiper.isBeginning && diff >= tolerance) {
      swiper.slideTo(totalSlidesLen - 1, 0)
      // 最後のスライド上で右にスワイプさせたときは最初のスライドに飛ばす
    } else if (swiper.isEnd && diff <= -tolerance) {
      // 一瞬待ってから最初のスライドに飛ばす
      // NOTE: 少し遅らせないと、最初のスライドに戻った後に右スワイプ判定が走って2番目のスライドに飛ばされてしまうため
      setTimeout(() => {
        swiper.slideTo(0, 0)
      }, 1)
    }
  }

  return {
    getSlideshowResponse,
    handleSlideChange,
    handleTouchStart,
    handleTouchEnd,
    handleAutoplay
  }
}

export const useDownloadVideoFromGetSlideshowResponse = (
  slides: GetSlideshowResponse | undefined
) => {
  const token = useAuthContext()

  useEffect(() => {
    slides?.slides?.forEach((slide) => {
      if (slide.type !== 'movie') return

      const apiUrl = (import.meta.env.VITE_API_URL ?? '') as string
      
      fetch(`${apiUrl}/api/contents/movie/${slide.id ?? ''}`, {
        headers: {
          Authorization: `Bearer ${token ?? ''}`
        }
      }).then(async (response) => {
        if (response.status === 200) {
          const res: Movie = await response.json()
          // TODO: loading=trueにする。状態はindexedDBには保存しない

          if (!res.movie_file) return
          if (await db.videos.get({ filePath: res.movie_file })) return

          // ローカルで本番のAPIを叩いて動作確認したいときは以下のコメントを外す
          // fetch(res.movie_file.replace("https://www.gk-board.com", "http://localhost:5173"))
          fetch(res.movie_file)
            .then(async (response) => await response.blob())
            .then((data) => {
              try {
                db.videos.add({
                  filePath: res.movie_file ?? '',
                  blob: data
                })
              } catch (error) {
                // TODO:エラーハンドリング
              }
            })
        }
      })
    })
  }, [slides, token])
}
