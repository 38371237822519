import iconNoisePng from 'src/assets/images/noise-meter/blackBoard/icon_noise.png'
import iconVibrationPng from 'src/assets/images/noise-meter/blackBoard/icon_vibration.png'
import dbPng from 'src/assets/images/noise-meter/blackBoard/db.png'
import { formatDate } from 'src/utils/formatDate'
import { FloatingText } from '../../FloatingText'
import { ExtendedNoiseVibration } from '../../types'

export const ExamplePageDefault = ({
  noiseValue,
  vibrationValue,
  contractName
}: ExtendedNoiseVibration) => {  
  return (
    <div className="noiseMeter horizontal" style={{ backgroundColor : 'gray'}}>
      <header className="header" style={{padding : '4px 8px'}}>
        <h1 className="header__title" style={{ fontSize : '14px'}}>{ contractName }</h1>
        <p className="header__date">
          {/* displayDate && formatDate(new Date(displayDate), 'M月d日H:mm時点') */}
        </p>
      </header>

      <div className="dataWrapper" style={{ display : 'block', top : '55%'}}>
        <div className="noiseWrapper">
          <h2 className="noiseWrapper__title" style={{ color : 'white', fontWeight : 'bold', fontSize : '8vw' }}>
            騒音
          </h2>
          <div className="valueWrapper" style={{ justifyContent : 'flex-end' }}>
            <p className="valueWrapper__strings" style={{ fontSize : '32vw'}}>{noiseValue}</p>
            <span className="valueWrapper__db" style={{ color : 'white', fontWeight : 'bold',  }}>
              dB
            </span>
          </div>
        </div>

        <div className="vibrationWrapper">
          <h2 className="vibrationWrapper__title" style={{ color : 'white', fontWeight : 'bold', fontSize : '8vw' }}>
            振動
          </h2>
          <div className="valueWrapper" style={{ justifyContent : 'flex-end' }}>
            <p className="valueWrapper__strings" style={{ fontSize : '32vw'}}>{vibrationValue}</p>
            <span className="valueWrapper__db" style={{ color : 'white', fontWeight : 'bold',  }}>
              dB
            </span>
          </div>
        </div>

      </div>

    </div>
  )
}
