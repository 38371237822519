import { Util } from 'src/utils'
import { formatDate } from 'src/utils/formatDate'
import { Props } from '../../types'
import { getCharacterImagesByWbgt, getTextImagesByWbgt } from './utils'

export const HeatStrokePageDefault = ({ data }: Props) => {
  const wbgt = data.value ?? 0
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  const characterImages = getCharacterImagesByWbgt(wbgt)
  const textImages = getTextImagesByWbgt(wbgt)
  const className = Util.wbgt.getClassNameByWbgt(wbgt)

  const wbgtColors = [
    '#218CFF',
    '#A0D2FF',
    '#FAF500',
    '#FF9600',
    '#FF2800'
  ]
  
  return (
    <div className="noiseMeter _default horizontal" style={{ backgroundImage : 'none', backgroundColor : 'gray', color : 'white', height : '100vh', overflow : 'hidden' }}>
      <header className="header" style={{padding : '4px 8px'}}>
        <h1 className="header__title" style={{ fontSize: '6vw' }}>{ data.wbgt_text }</h1>
        <p className="header__date" style={{ fontSize : '6vw'}}>
          { data.display_date &&
            formatDate(new Date(data.display_date), 'M月d日H:mm時点') }
        </p>
      </header>

      <main className="content_body" style={{ padding: '4px 8px' }}>

        <div  style={{padding : '4px 4px', display : 'flex'}}>
          <div style={{ fontSize: '6vw', width: '35%', fontWeight : 'bold'}}>
            WBGT値
          </div>
          <div style={{ fontSize : '32vw', color : wbgtColors[dangerLevel - 1], lineHeight : '32vw', textAlign : 'right', width : '55%', fontWeight : 'bold' }}>
            {wbgt}
          </div>
          <div style={{ fontSize: '6vw', fontWeight: 'bold', marginTop: '20vw', width: '10vw', textAlign: 'right' }}>
            ℃
          </div>
        </div>

        <div  style={{padding : '4px 4px', display : 'flex'}}>
          <div style={{ fontSize: '6vw', height: '6vw', width: '50%', fontWeight : 'bold'}}>
            警戒レベル
          </div>
          <div style={{ fontSize : '32vw', color : wbgtColors[dangerLevel - 1], lineHeight : '1.0', textAlign : 'right', width : '40%', fontWeight : 'bold' }}>
            {dangerLevel}
          </div>
          <div style={{ fontSize: '6vw', fontWeight: 'bold', marginTop: '20vw', width: '10vw', textAlign: 'right' }}>
          </div>
        </div>

        <div style={{ overflow: 'hidden', whiteSpace : 'nowrap', lineHeight : '1.0' }}>
          <div className="value_text" style={{ fontSize : '5vw', display : 'inline-block', paddingLeft : '100%', animation : 'marquee 50s linear infinite', fontWeight : 'bold'}}>
            {Util.wbgt.getDescriptionByWbgt(wbgt)}
          </div>
        </div>
        
      </main>
    </div>
  )
}
