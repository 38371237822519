import icon01dPng from 'src/assets/images/weather-forecast/eva/icon_weather/101.svg'
// import icon02dPng from "src/assets/images/weather-forecast/eva/icon_weather/02d.png";
// import icon03dPng from "src/assets/images/weather-forecast/eva/icon_weather/03d.png";
// import icon04dPng from "src/assets/images/weather-forecast/eva/icon_weather/04d.png";
// import icon09dPng from "src/assets/images/weather-forecast/eva/icon_weather/09d.png";
// import icon10dPng from "src/assets/images/weather-forecast/eva/icon_weather/10d.png";
// import icon11dPng from "src/assets/images/weather-forecast/eva/icon_weather/11d.png";
// import icon13dPng from "src/assets/images/weather-forecast/eva/icon_weather/13d.png";
// import icon50dPng from "src/assets/images/weather-forecast/eva/icon_weather/50d.png";
import iconTempSvg from 'src/assets/images/weather-forecast/eva/icon_temp/temp_001.svg';
import { Weather } from 'generated/backendSchemas'
import { Util } from 'src/utils'

export const ExamplePageEva = ({ weather }: Weather) => {
  return (
    <div className="weatherForecast eva horizontal">
      <header className="header header--eva">
        <h1 className="header__title header__title--eva"><span className='header__spotName'>埼玉県</span>週間天気予報</h1>
      </header>
      <div className="content">
        <ul className="weatherList weatherList--eva">
          {(weather ?? []).map((item, index) => {
            const additionalClass =
            index === 0
            ? 'weatherList__item--holiday'
            : index === 5
            ? 'weatherList__item--sat'
            : index === 6
            ? 'weatherList__item--sun'
            : ''
            const caption = Util.wbgt.getCaptionByWbgt(item.wbgt_level ?? 0)
            return (
              <li key={index} className={`weatherList__item ${additionalClass}`}>
                <p className="weatherList__day">{item.date}</p>
                <p className="weatherList__dayOfWeek">
                  {item.day_of_week}
                </p>
                <div className="weatherList__dayOfWeather">
                  {/* TODO: 出しわけする */}
                  {/* <img src={icon01dPng} alt="" /> */}
                  <img src={item.weather_mark} alt="晴れ" />
                </div>
                <div className="weatherList__temperature">
                  <p className="weatherList__temperature--min">
                    {item.lowest}
                    <span>℃</span>
                  </p>
                  <p className="weatherList__temperature--max">
                    {item.highest}
                    <span>℃</span>
                  </p>
                </div>
                <div className="weatherList__rainyPercent">
                  <p className="weatherList__rainyPercent--title">
                    降水
                    <br />
                    確率
                  </p>
                  <p className="weatherList__rainyPercent--value">
                    {item.precipitation_percentage}
                    <span>%</span>
                  </p>
                </div>
                <div className="weatherList__tempImg">
                    <img src={iconTempSvg} alt={caption} />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
