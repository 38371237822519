import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import './styles/_reset.scss'

// NOTE: 何かしら壊れた時のために定期的にリロードする
/* ---
2024.09.05 別途検討
setTimeout(() => {
  const code = localStorage.getItem('code')
  if (code) {
    location.href = `/auth/${code}`
  }
}, 1000 * 60 * 60)
--- */

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
