import { ReactNode, useLayoutEffect, useRef, useState } from 'react'

type Props = {
  isEva?: boolean
  children: ReactNode
}

export const FloatingText = ({ isEva = false, children }: Props) => {
  const floatingBoxRef = useRef<HTMLDivElement>(null)
  const floatingTextRef = useRef<HTMLDivElement>(null)
  const [className, setClassName] = useState('')

  useLayoutEffect(() => {
    if (!floatingBoxRef.current || !floatingTextRef.current) return
    const width = floatingBoxRef.current.offsetWidth
    const floatingTextWidth = floatingTextRef.current.offsetWidth
    if (width < floatingTextWidth) {
      setClassName('js-active')
    } else {
      setClassName('')
    }
  }, [children])

  return (
    <div id="js-floating-box" className="footer__info" ref={floatingBoxRef} style={{ flexBasis : '100%' }}>
      <div
        id="js-floating-text"
        className={`footer__infoBox ${className}`}
        ref={floatingTextRef}
      >
        <p
          className={`footer__infoText ${isEva ? 'footer__infoText--eva' : ''}`}
        >
          {children}
        </p>
      </div>
    </div>
  )
}
