import { ExtendedNoiseVibration } from '../../types'
import { getNoiseImage, getVibrationImage } from './utils'

// import './normalize.css'
import './fonts.css'
import './style.css'
import './vibration.css'

export const ExamplePageDefault = ({
  noiseValue,
  vibrationValue,
  contractName
}: ExtendedNoiseVibration) => {  

  const noises = getNoiseImage(Number(noiseValue))
  const vibrations = getVibrationImage(Number(vibrationValue))

  return (
  <div className="container vibration">
    <div className="page-contents">
      <header className="header">
        <h1 className="header__title">現在の騒音・振動</h1>
{/*
        <div className="header__datetime">
          <div className="header__date">
            <span>4</span>
            <span className="header__datetime-unit">月</span>
            <span>17</span>
            <span className="header__datetime-unit">日</span>
          </div>
          <div className="header__time">
            <span className="header__datetime-unit header__time-unit">AM</span>
            <span>10:30</span>
          </div>
        </div>
*/}
      </header>

      <main className="contents">
        <section className="vibration-content" style={{ width : '86svw' }}>
            <h2 className="vibration-content__title">{ contractName }</h2>
          <div className="vibration-content__list" style={{ display : 'block'}}>
            <section className="vibration-content__item" style={{ width : '74svw', height : '30svh', marginBottom : '5svh' }}>
              <header className="vibration-content__header" style={{ display : 'flex', height : '28svh' }}>
                <h3 className="vibration-content__item-heading" style={{ width : '6svw', fontSize : '5svh' }}>騒<br />音</h3>
                <div className="vibration-content__item-value font-numbers" style={{ width : '45svh', fontSize : 'calc((200 / 1080) * 100vh)' }}><span>{ noiseValue }</span></div>
                <div className="vibration-content__item-unit" style={{ width : '20svw', display : 'block' }}>dB</div>
              </header>
            </section>
            <section className="vibration-content__item" style={{ width : '74svw', height : '30svh' }}>
              <header className="vibration-content__header" style={{ display : 'flex', height : '28svh' }}>
                <h3 className="vibration-content__item-heading" style={{ width : '6svw', fontSize : '5svh' }}>振<br />動</h3>
                <div className="vibration-content__item-value font-numbers" style={{ width : '45svh', fontSize : 'calc((200 / 1080) * 100vh)' }}><span>{ vibrationValue }</span></div>
                <div className="vibration-content__item-unit" style={{ width : '20svw', display : 'block' }}>dB</div>
              </header>
            </section>
          </div>
        </section>
      </main>

      </div>
    <footer className="footer">
      <div className="footer__note">
        <div className="marquee">
          <span></span>
        </div>
      </div>
    </footer>
  </div>
  )
}
