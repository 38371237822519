import { Util } from 'src/utils'
import { formatDate } from 'src/utils/formatDate'
import { Props } from '../../types'
import { getCharacterImageByWbgt, getBackgroundColorByWbgt, getTextImagesByWbgt } from './utils'
import { format } from 'date-fns'

// import './normalize.css'
import './style.css'
import './wbgt.css'

import redFace from 'src/assets/images/heatstroke/new/img_WBGT_red.svg'

export const HeatStrokePageDefault = ({ data }: Props) => {
  const wbgt = data.value ?? 0
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  const characterImages = getCharacterImageByWbgt(wbgt)
  const backgroundColorClass = "wbgt-content " + getBackgroundColorByWbgt(wbgt)

  const textImages = getTextImagesByWbgt(wbgt)
  
  return (
  <div className="container wbgt">
    <div className="page-contents">
      <header className="header">
          <h1 className="header__title">WBGT（暑さ指数）</h1>
        <div className="header__datetime">
          <div className="header__date">
              <span>{ data.display_date && format(new Date(data.display_date), 'M') }</span>
            <span className="header__datetime-unit">月</span>
              <span>{ data.display_date && format(new Date(data.display_date), 'd') }</span>
            <span className="header__datetime-unit">日</span>
          </div>
          <div className="header__time">
            <span className="header__datetime-unit header__time-unit">{ data.display_date && format(new Date(data.display_date), 'a') }</span>
              <span>{ data.display_date && format(new Date(data.display_date), 'HH:mm') }</span>
          </div>
        </div>
      </header>

      <main className="contents">
        <section className={backgroundColorClass}>
          <div className="wbgt-content__sign">
            <h2 className="wbgt-content__sign-heading">危険</h2>
            <div className="wbgt-content__sign-img" style={{ display : 'flex', justifyContent : 'center'}}><img src={characterImages} width="334" height="334" alt="" /></div>
              <p className="wbgt-content__sign-description"><img src={ textImages[0] } width="334"/></p>
          </div>
          <div className="wbgt-content__list">
            <section className="wbgt-content__list-item">
              <h3 className="wbgt-content__list-item-title">WBGT値</h3>
              <div className="wbgt-content__list-item-value">{ wbgt}</div>
              <div className="wbgt-content__list-item-unit">℃</div>
            </section>
            <section className="wbgt-content__list-item">
              <h3 className="wbgt-content__list-item-title">警戒レベル</h3>
                <div className="wbgt-content__list-item-value">{ dangerLevel }</div>
              <div className="wbgt-content__list-item-unit"></div>
            </section>
          </div>
        </section>
      </main>
    </div>
    <footer className="footer">
      <div className="footer__note">
        <div className="marquee">
          <span>{Util.wbgt.getDescriptionByWbgt(wbgt)}</span>
        </div>
      </div>
        {/* <div className="footer__tel">清水建設株式会社 TEL 0120-123-456</div> */}
    </footer>
  </div>
  )
}
